
import { computed, onBeforeMount, reactive, ref, toRaw, watch } from 'vue'
import { useRoute } from 'vue-router'
import { FormActionType, SheetSongTypeList, SheetTypeList } from './config'
import UploadSong from './UploadSong.vue'
import UploadSheet from './UploadSheet.vue'
import UploadPoster from './UploadPoster.vue'
import UploadEvmsSheet from './UploadEvmsSheet.vue'
import UploadMultiVoice from './UploadMultiVoice.vue'
import SelectBook from './SelectBook.vue'
import { debounce } from '@/utils/common'
import * as service from '@/services/songs'
import {
  SearchOutlined,
  InfoCircleOutlined
} from '@ant-design/icons-vue'
export default {
  emits: ['onOk'],
  components: { UploadSong, UploadSheet, UploadPoster, SelectBook, SearchOutlined, InfoCircleOutlined, UploadEvmsSheet, UploadMultiVoice },
  props: {
    actionType: {
      type: String,
      default: () => { return FormActionType.CREATE }
    },
    // 如果是编辑，那么current代表的是当前选中的歌曲的信息
    current: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    // 是否存在相同code
    const hasSameCode = ref(false)
    const formRef = ref()
    const currentValue = computed(() => (props.current))
    const isEdit = computed(() => props.actionType === FormActionType.EDIT)
    const uploadPosterRef = ref()

    const formData = ref({
      name: '',
      song_code: '',
      py: '',
      song_type: undefined,
      sheet_type: undefined,
      speed: undefined,
      picture_url: [],
      evxml_file_url: '',
      evxml_file_md5: '',
      evxml_file_size: undefined,
      evms_file_url: '',
      evms_file_md5: '',
      evms_file_size: undefined,
      ori_file_url: '',
      ori_file_md5: '',
      ori_file_size: undefined,
      acc_file_url: '',
      acc_file_md5: '',
      acc_file_size: undefined,
      acc_speed: undefined,
      book_id: undefined,
      book_section_id: undefined,
      extend_data: {
        multi_part_song: []
      },
      ...toRaw(currentValue.value)
    })

    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }

    const bookName = ref(currentValue.value.bookName || '')
    const sectionName = ref(currentValue.value.sectionName || '')
    const bookNameInput = computed(() => `${bookName.value ? bookName.value + '/' : ''}${sectionName.value}`)

    const bookInfo = ref({
      book_id: currentValue.value?.book_id,
      book_section_id: currentValue.value?.book_section_id
    })

    const tagList = ref(currentValue.value?.tags || [])
    const tagOpt = ref([])

    const getTags = async name => {
      const getTagsInfo = route.params.type === '1' ? service.getOfficialTag : service.getPersonalTag
      const data = await getTagsInfo({ keyword: name, per_page: 10, page: 1 })
      if (!data.items || data.items.length < 1) {
        data.items = [{ tag: name }]
      }
      return data
    }

    const handleSerach = debounce(async value => {
      const data = await getTags(value)
      tagOpt.value = data.items.map(item => { return { label: item.tag, value: item.tag } })
    })

    onBeforeMount(async () => {
      await handleSerach('')
    })

    const codeLengthValidate = (rule, value) => {
      if (!value) {
        Promise.reject(new Error('请输入歌曲识别码'))
      }
      return Promise.resolve()
    }

    const accSongSpeedValidate = (rule, value) => {
      if (formData.value.acc_file_url && formData.value.acc_file_url.length > 0) {
        if (!value) {
          return Promise.reject(new Error('伴唱歌曲存在时必须填写伴唱速度'))
        }
      }
      return Promise.resolve()
    }

    const handleSelectBook = (params) => {
      formData.value = {
        ...toRaw(formData.value),
        book_id: params.book_id,
        book_section_id: params.book_section_id
      }
      bookName.value = params.bookName
      sectionName.value = params.sectionName
    }

    const onSubmit = () => {
      formRef.value.validate().then(() => {
        const params = formData.value
        if (params.book_id === null || params.book_id === undefined || params.book_section_id === null || params.book_section_id === undefined) {
          params.book_id = 0
          params.book_section_id = 0
        }
        emit('onOk', params, tagList.value)
      })
    }

    const handleUploadFormChange = info => {
      console.log('upload ====> ', info)
      formData.value = info
    }
    const checkSameCode = debounce(async () => {
      const { exist } = await service.checkSheetSongCode({ song_code: formData.value.song_code })
      hasSameCode.value = exist
    })

    watch(() => formData.value.song_code, (v) => {
      if (v.length === 7 && route.params.type === '1') {
        checkSameCode()
      } else {
        hasSameCode.value = false
      }
    })

    return {
      route,
      formRef,
      labelCol,
      wrapperCol,
      formData,
      SheetSongTypeList,
      SheetTypeList,
      uploadPosterRef,
      bookName,
      bookNameInput,
      sectionName,
      isEdit,
      bookInfo,
      tagList,
      tagOpt,
      hasSameCode,
      handleSerach,
      onSubmit,
      handleSelectBook,
      handleUploadFormChange,
      codeLengthValidate,
      accSongSpeedValidate
    }
  }
}
